import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import RS from 'react-select';
import RSAsnyc from 'react-select/async';

import Field from '../high-order/Field';
import Tooltip from '../shared/Tooltip';
import { Label, Optional } from '../common';
import Icon from '../shared/Icon';
import theme from '../theme';

class Select extends Component {

  shouldComponentUpdate(nextProps) {
    const {
      disabled,
      error,
      flex,
      label,
      multiple,
      options,
      placeholder,
      required,
      touched,
      value,
      defaultOptions,
      activeTheme,
      loading,
      displayTooltip,
      failedLoad,
      onClear
    } = this.props;
    const {
      disabled: nextDisabled,
      error: nextError,
      flex: nextFlex,
      label: nextLabel,
      multiple: nextMultiple,
      options: nextOptions,
      placeholder: nextPlaceholder,
      required: nextRequired,
      touched: nextTouched,
      value: nextValue,
      defaultOptions: nextDefaultOptions,
      activeTheme: nextActiveTheme,
      loading: nextLoading,
      displayTooltip: nextDisplayTooltip,
      failedLoad: nextFailedLoad
    } = nextProps;

    if (
      disabled !== nextDisabled ||
			error !== nextError ||
			flex !== nextFlex ||
			label !== nextLabel ||
			multiple !== nextMultiple ||
			placeholder !== nextPlaceholder ||
			required !== nextRequired ||
			touched !== nextTouched ||
			options.length !== nextOptions.length ||
			value.length !== nextValue.length ||
			defaultOptions.length !== nextDefaultOptions.length ||
            activeTheme !== nextActiveTheme ||
            displayTooltip !== nextDisplayTooltip ||
            loading !== nextLoading ||
            failedLoad !== nextFailedLoad
    ) {
      return true;
    }

    // checks if the options have changed
    const optionsChanged =
			Array.isArray(nextOptions) &&
			nextOptions.some((opt, index) => opt !== options[index]);

    // checks if the values have changed
    const valuesChanged =
			Array.isArray(nextValue) &&
			nextValue.some((val, index) => val !== value[index]);

    const defaultOptionsChanged =
			Array.isArray(nextDefaultOptions) &&
			nextDefaultOptions.some((opt, index) => opt !== defaultOptions[index]);

    return optionsChanged || valuesChanged || defaultOptionsChanged;
  }

  constructor(...args) {
    super(...args);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.tooltipRef = React.createRef();
    this.showToolTip = this.showToolTipChange.bind(this);
  }

  handleChange(data) {
    const { onChange, onClear } = this.props;
    const update = data instanceof Array ? data : [data];
    onChange(update);
    if(data === null) {
        onClear();
    }
  }

  handleInputChange(data) {
    const { onInputChange } = this.props;
    onInputChange(data);
  }

  showToolTipChange() {
      const { showToolTip } = this.props;
      showToolTip();
  };  

  hideToolTip() {
    this.displayTooltip = false;
  }

  render() {
    const {
      disabled: isDisabled,
      error,
      flex,
      label,
      multiple: isMulti,
      options,
      placeholder,
      required,
      touched,
      value,
      defaultOptions,
      allowsArbitrary,
      loadOptions,
      shouldAutoFocus,
      isNIBRS,
      tooltip,
      activeTheme,
      loading,
      failedLoad,
      fetchId,
      displayTooltip,
    } = this.props;
    const showError = !!(touched && error);
    const showLabel = !!label;

    let async = false;
    let structuredProps = {
      classNamePrefix: 'RS',
      isDisabled,
      isMulti,
      placeholder,
      showError,
      value,
      onChange: this.handleChange,
      onInputChange: this.handleInputChange,
      activeTheme: activeTheme,
      noOptionsMessage: () => 'No Options',
      tooltipRef: this.tooltipRef,
      showToolTip: this.showToolTip,
      displayTooltip
    };
    if (loadOptions) {
      async = true;
      structuredProps = {
        ...structuredProps,
        defaultOptions,
        loadOptions,
      };
    } else {
      structuredProps = {
        ...structuredProps,
        options,
      };
    }

    let disabledString = [];
    if(value.length > 0) {
        value.map((item) => {
            disabledString.push(item?.display);
        });
    }

    return (
      <Wrapper flex={flex} showLabel={showLabel}>
        {showLabel && (
          <StyledLabel ref={structuredProps.tooltipRef} activeTheme={activeTheme} isNIBRS={isNIBRS}>
            {label} 
            {tooltip &&
              <StyledIcon display={'inline'} onPress={() => structuredProps.showToolTip()} color={displayTooltip ? theme.red : activeTheme.iconColor} name={displayTooltip ? 'x' : 'help-circle'} font="Feather" size={14} />
            }
            {value.length > 1 && ` (${value.length})`}
            {!required && <Optional>{' - Optional'}</Optional>}
          </StyledLabel>
        )}
        {tooltip &&
          <Tooltip tooltipRef={structuredProps.tooltipRef} shown={displayTooltip} content={tooltip} direction="top" delay={400} />
        }
        {async && <StyledRSAsync isClearable={(typeof(value[0]) !== 'undefined' && !isMulti) ? true : false} isSearchable={allowsArbitrary} autoFocus={shouldAutoFocus} {...structuredProps} />}
        {!async && 
          loading ?
            failedLoad ?
                <span><em>Failed to load</em></span>
                :
                <Icon color={activeTheme.spinnerIcon} name="loader" font="Feather" size={22} spin />
            :
            isDisabled ?
                <Border activeTheme={activeTheme}>
                {value.length > 0 && (
                    <span>{disabledString.toString()}</span>
                )}
                </Border>
                :
                <StyledRS isClearable={(typeof(value[0]) !== 'undefined' && !isMulti) ? true : false} isLoading={loading} activeTheme={activeTheme} isSearchable={allowsArbitrary} autoFocus={shouldAutoFocus} {...structuredProps} />
        }
        {showError && <Label error>{error}</Label>}
      </Wrapper>
    );
  }
}

Select.displayName = 'Select';

Select.defaultProps = {
  disabled: false,
  error: '',
  flex: 1,
  label: null,
  multiple: false,
  options: [],
  placeholder: null,
  required: true,
  touched: false,
  value: [],
  onChange: () => {},
  onInputChange: () => {},
  defaultOptions: [],
  loadOptions: null,
  isNIBRS: false,
  tooltip: false,
  showToolTip: () => {},
  displayTooltip: false,
};

Select.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  flex: PropTypes.number,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  showTooltip: PropTypes.func,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  loadOptions: PropTypes.func,
  isNIRBS: PropTypes.bool,
};

export default Select;
export const FieldSelect = Field(Select);

const styledSelect = `
> .RS__control {
    font-family: '${theme.baseFont}';
	align-items: flex-start;
	border-radius: ${theme.borderRadius}px;
	min-height: ${theme.inputHeight + 6}px; 
	width: 100%;
	
	${({ showError }) =>
    showError &&
		css`
			border: 1px solid ${theme.error};
		`}
	&.RS__control--is-focused {
		border-color: ${theme.primary};
		/*box-shadow: 0 0 0 1px ${theme.primary};*/
		box-shadow: none;
	}
	.RS__multi-value__label {
		align-items: center;
		height: 24px;
		line-height: 21px;
		padding: 0;
		padding-left: 6px;
	}
	.RS__indicators {
        min-height: ${theme.inputHeight + 4}px;
        background: ${theme.greywhite};
        border-radius: 0 ${theme.borderRadius}px ${theme.borderRadius}px 0;
        
        .RS__indicator-separator {
            width: 0;
        }

        .RS__dropdown-indicator, .RS__clear-indicator {
            padding: 0 2px 0 2px;
        }
	}
	.RS__value-container {
        width: 150px;
        border-radius: 4px;
		overflow: auto;
		.RS__single-value {
			top: 40%;
		}
	}
}
&.RS--is-disabled {
	cursor: not-allowed;
	pointer-events: auto;
	> .RS__control--is-disabled {
		pointer-events: none;
	}
}
> .RS__menu {
    z-index: 99998;
	bottom: unset;
    font-family: '${theme.baseFont}';
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		left: 0;
	}
	
	> .RS__menu-list {
		flex-direction: column;
		width: 100%;

        &::-webkit-scrollbar {
          width: .5em;
        }
           
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
        }
           
        &::-webkit-scrollbar-thumb {
          background-color: ${theme.grey60};
          outline: 1px solid ${theme.grey50};
        }

		> .RS__option {
            cursor: pointer;
            background: inherit;
            color: black;
        }
        
        > .RS__option--is-focused {
            background: rgb(${theme.royalBlueRgb});
            background: linear-gradient(180deg, rgba(${theme.royalBlueRgb},0.10) 100%, rgba(${theme.royalBlueRgb},0.10) 100%);
        }
	}
}
`;

const StyledRS = styled(RS)`
	${styledSelect}
    .RS__value-container {
        background-color: ${({ activeTheme }) => activeTheme?.inputBackground};
        padding: 0 0 0 4px;
        filter: ${({ activeTheme }) => activeTheme?.filter};
        .RS__single-value {
            color: ${({ activeTheme }) => activeTheme?.inputText};
        }
    }

        .RS__menu {
            background: ${({ activeTheme }) => activeTheme?.dropDownBackground};
        }
`;

const StyledRSAsync = styled(RSAsnyc)`
	${styledSelect}
`;

const StyledLabel = styled(Label)`
    color: ${({ isNIBRS, activeTheme }) => isNIBRS ? activeTheme?.labelNIBRS : activeTheme?.label} !important;
    margin-left: ${theme.labelMarginLeft}px;
    display: flex;
`;

const StyledIcon = styled(Icon)`
margin-left: 5px;
`;

const Wrapper = styled.div`
	flex: ${({ flex }) => flex};
	flex-direction: column;
	min-height: ${({ showLabel }) =>
    theme.wrapperHeight - Number(!showLabel) * theme.labelFullHeight}px;
	padding: ${theme.componentPadding};
	width: 100%;
  position: relative;
`;

const Border = styled.div`
background: ${({ activeTheme }) => activeTheme?.inputBackground};
border-radius: 4px;
border: 1px solid #e6e6e6;
padding: ${theme.inputPadding}px ${theme.inputPadding * 2}px;
line-height: 12px;
min-height: 12px;
cursor: not-allowed;
`;
