import { Auth } from '@aws-amplify/auth';
import { ConsoleLogger } from '@aws-amplify/core';
import configs from 'qcp-js-ui-core/configs';
import { setNotification } from '../store/actions/notification';

const TOKEN_KEY = 'AuthenticationToken';

const loginStateChannel = new BroadcastChannel("loginStateChannel");

const sendLoginState = (status, username, message = '') => {
  loginStateChannel.postMessage(JSON.stringify({status, username, message}));
}

let signInListener = () => undefined;
export const signIn = async (username, password) => {
  sendLoginState('PROCESSING');
  Auth.configure(configs.cognito);
  let user;
  let message;
  try {
    user = await Auth.signIn(username, password);
  } catch(e) {
    message = e?.message || null;
    //nop
  }
  if(user) {
    if(user?.challengeName == "NEW_PASSWORD_REQUIRED") {
        sendLoginState('CONFIRMUSER', username, message);
    } else {
        const token = (await Auth.currentSession()).accessToken.jwtToken;
        setStorageToken(token);
        localStorage.setItem('loggedInUser', username);
        sendLoginState('SUCCESS', username, message);
    }
  } else {
    sendLoginState('FAIL', username, message);
  }
  return user;
};

export const setSignInListener = (listener) => { signInListener = listener }

const setStorageToken = (token) => {
    window.localStorage.setItem(TOKEN_KEY, token);
}

const getToken = async () => {
    if(configs.cognito) {
        try {
            Auth.configure(configs.cognito);
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            localStorage.setItem('AuthenticationToken', currentSession.accessToken.jwtToken);
            return currentSession.accessToken.jwtToken
        } catch (e) {
            console.log('Unable to refresh or validate Token', e);
        }
    } else {
        return window.localStorage?.getItem(TOKEN_KEY);
    }
}

const getCognitoToken = async () => (await Auth.currentSession()).accessToken.jwtToken;

const signOut = () => {
    localStorage.removeItem('loggedInUser');
    sendLoginState('LOGOUT', null);
    if(configs.cognito) {
        localStorage.removeItem(TOKEN_KEY);
        Auth.signOut;
        Auth.configure(null);
    } else {
        window.localStorage?.setItem(TOKEN_KEY, undefined);
    }
    
}

export const forgotPassword = async (username) => {
  Auth.configure(configs.cognito);
  try {
    await Auth.forgotPassword(username);
    sendLoginState('FORGOTSUCCESS', username);
  } catch(e) {
    const message = e?.message || null;
    sendLoginState('FORGOTFAIL', username, message);
  }
}

export const resetPassword = async (username, code, password) => {
  Auth.configure(configs.cognito);
  try {
    await Auth.forgotPasswordSubmit(username, code, password);
    sendLoginState('RESETSUCCESS', username);
  } catch(e) {
    const message = e?.message || null;
    sendLoginState('RESETFAIL', username, message);
  }
}

export const confirmNewPassword = async (username, temporary, password) => {
    Auth.configure(configs.cognito);
    let requiredAttributes = {};
    try {
      let user = await Auth.signIn(username, temporary);
      await Auth.completeNewPassword(user, password, requiredAttributes);
      sendLoginState('CONFIRMUSERSUCCESS', username);
    } catch(e) {
      const message = e?.message || null;
      sendLoginState('CONFIRMUSERFAIL', username, message);
    }
  }

export default { signIn, getToken, signOut, getCognitoToken };
