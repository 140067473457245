
import { updateJwtToken } from 'qcp-js-ui-core/utils/webrequest';
import configs from 'qcp-js-ui-core/configs';
import { find } from 'qcp-js-ui-core/utils/service-registry';

import { logout } from '../App';

const { getToken } = find('cognito-session');

export const getCookie = name => {
  let value;
  document.cookie.split('; ').forEach(element => {
    const [n, v] = element.split('=');
    if (n === name) { value = v; }
  });
  return value;
};

const setJwtToken = async () => {
  const storedToken = localStorage.getItem('AuthenticationToken');
  
  if(configs.cognito) {
    if(typeof storedToken !== 'undefined' && storedToken !== 'undefined') {
        updateJwtToken(await getToken());
    } else {
        logout();
    }
  } else {
    updateJwtToken(getCookie('_token'));
  }
};

export default setJwtToken;
