import React, { memo, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IconDropdown from '../shared/IconDropdown';
import Icon from '../shared/Icon';
import styled, { ThemeContext } from 'styled-components';
import TopNavigationSystem from '../../TopNavigationSystem';
import Toggle from '../Toggle';
import { useDarkMode } from '../useDarkMode'

import theme from '../theme';

const Header = ({ secondary, options, handleThemeChange }) => {
  const userQuin = useSelector((state) => state.rms?.session?.user);
  const userSession = useSelector((state) => state.rms?.[userQuin] ? state.rms[userQuin] : {});
  const actionStatus = useSelector(state => state.rms?.actionStatus?.status);
  const [user, setUser] = useState({});
  const [themee, themeToggler] = useDarkMode();
  const themeContext = useContext(ThemeContext);
  const isDirty = useSelector(state => false);

  useEffect(() => {
    if(userSession?.firstName && userSession?.lastName) {
      setUser(userSession);
    }
    handleThemeChange(themee);
  }, [actionStatus, themee, userSession]);
  
  return (
    <Wrapper className={'header-wrapper'} activeTheme={themeContext}>
      <TopNavigationSystem />
        {isDirty && <UploadableIcon key={3} color={theme.white} name="upload-cloud" font="Feather" size={16} />}
      <Toggle theme={themee} toggleTheme={themeToggler} />
      {secondary && <Image src={secondary} />}
      {!secondary && (
        <Icons>
          <IconWrapper>
            <IconDropdown
              key={1}
              color={theme.sideBarBackground}
              //options={options}
              //newOptions={newOptions}
              name="bell"
              font="Feather"
              size={16}
            />
          </IconWrapper>
          {Object.keys(user).length > 0 &&
            <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
          }
          <IconDropdown key={2} options={options} color={theme.white} name="chevron-down" font="Feather" size={20} />
        </Icons>
      )}
    </Wrapper>
  );
};

Header.displayName = 'Header';

Header.defaultProps = {
  secondary: '',
  options: [],
  newOptions: 0,
};

Header.propTypes = {
  secondary: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  newOptions: PropTypes.number,
};

export default memo(Header);

const Text = styled.span`
color: ${theme.greyBox};
font-size: .75rem;
font-weight: 300;
letter-spacing: 1px;
margin: 0 10px 0 10px;
`;

const Image = styled.img`
height: ${theme.headerHeight / 2}px;
`;

Image.displayName = 'Image';

const Logo = styled.div`
justify-content: space-evenly;
width: 110px;
align-items: center;
`;

Logo.displayName = 'Logo';

const LogoText = styled.h1`
font-family: inherit;
font-family: 'Avenir';
font-size: 16px;
font-weight: 500;
height: 21px;
letter-spacing: 0.5px;
margin: 0;
`;

LogoText.displayName = 'LogoText';

const IconWrapper = styled.div`
background: white;
border: 1px solid ${theme.grey};
border-radius: 4px;
padding: 4px;
`;

const Icons = styled.div`
justify-content: space-evenly;
align-items: center;
margin-right: 10px;
`;

const Wrapper = styled.div`
justify-content: space-between;
padding: 0;
position: sticky;
top: 0;
z-index: 9999;
background-color: ${({ activeTheme }) => activeTheme.headerBackground};
border-bottom: 1px solid ${theme.primary};
padding: 10px 0 3px 0;
height: 30px;
`;

const UploadableIcon = styled(Icon)`
padding: 5px 7px;
`;
