import React, { useState, useEffect, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import Field from '../high-order/Field';
import { useSelector, useDispatch } from 'react-redux';
import { updateProperty } from 'qcp-js-ui-core/store/actions/rms';
import { getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { getModel } from 'qcp-js-ui-core/models';
import { parseTimestamp } from 'qcp-js-ui-core/utils/date-time';
import PropTypes from 'prop-types';

import { Label } from '../common';
import Icon from '../shared/Icon';
import Tooltip from '../shared/Tooltip';

import DatePick from 'react-date-picker';
import DateTimePick from 'react-datetime-picker';
import DateRangePick from '@wojtekmaj/react-daterange-picker';
import DateTimeRangePick from '@wojtekmaj/react-datetimerange-picker';
import moment from 'moment';

import theme from '../theme';

const getFormattedDateTime = (inputDate) => {
  const rawFront = moment(inputDate).format('YYYY-MM-DDTHH:mm:ssZZ#');
  const front = `${rawFront.slice(0, rawFront.length - 3)}:${rawFront.slice(rawFront.length - 3)}`;
  const back = moment.tz.guess();
  return front + back;
};

const getAsDate = (mode, date) => mode === 'date' ?
  new Date(date.slice(0, 19)) :
  parseTimestamp(date?.replace(' ', 'T'))?.toDate();

const DateTimePicker = ({
  label,
  mode,
  enabled,
  binding1 = {},
  future,
}) => {
  const { id: id1, property: property1 } = binding1;
  const start = useSelector(state => getValueFromBindingObject(binding1, state)?.replace(' ', 'T'));
  const model = useSelector(state => binding1.id && getModel(binding1.id, state.rms));
  const qType = model?.qType;
  const modelSchema = useSelector((state) => state.rms.schemas.modelSchema?.[qType]?.dataSchema?.properties?.[property1]);
  const dispatch = useDispatch();
  const setValue = (value) => {
    if(!modelSchema) { return }
    const storable = modelSchema.format === 'date' ? value?.split('T')[0] : value;
    dispatch(updateProperty(id1, property1, storable));
  }
  return <UnboundDateTimePicker
    label={label}
    mode={mode}
    enabled={enabled}
    setValue={setValue}
    start={start}
    model={model}
    modelSchema={modelSchema}
    future={future}
  />;
}

export const UnboundDateTimePicker = ({
  label,
  mode,
  enabled,
  setValue,
  start,
  modelSchema,
  future,
}) => {
  const getDateAdjustedStart = (value) => {
    if(!value) { return value }
    if(mode !== 'date') { return value }
    const date = value.split('T')[0];
    const offset = moment().format().split('T')[1].split('-')[1].replace(':', '');
    return `${date}T00:00:00-0000`;
  }
  const range = false;
  const isNIBRS = modelSchema && modelSchema.nibrsName ? true : false;
  const tooltip = modelSchema && modelSchema.tooltip ? modelSchema.tooltip : false;
  const [ displayedDate, setDisplayedDate ] = useState({
    date: getDateAdjustedStart(start)?.replace(' ', 'T'),
    version: 1,
  });
  const [ isCalendarOpen, setIsCalendarOpen ] = useState(false);
  const [ isValid, setIsValid ] = useState(false);
  const [ isFutureDate, setIsFutureDate ] = useState(false);
  const themeContext = useContext(ThemeContext);

  const flex = '0 1 auto';

  const calendar = {
    calendarType: 'US',
  };

  const handleDateTimeChange = date => {
    moment.tz.setDefault(moment.tz.guess());
    let formatted = getFormattedDateTime(date);
    let d1 = moment(date).format('YYYY-MM-DD').valueOf();
    let d2 = moment().format('YYYY-MM-DD').valueOf();
        future && d1 > d2 ? setIsFutureDate(true) : setIsFutureDate(false);

    const rawYear = formatted?.split('-')?.[0];
    let useYear = rawYear?.slice(0, 4);
    if(Number(useYear) < 1900 && Number(useYear) >= 1000) {
      useYear = '1900';
    }
    const yearAdjusted = formatted.replace(new RegExp(`^${rawYear}-`), `${useYear}-`);
    if(Number(useYear) >= 1900) {
      setValue(yearAdjusted);
      const version = displayedDate.version + (rawYear.length > useYear.length ? 1 : 0);
      setDisplayedDate({ date: yearAdjusted, version });
      setIsValid(!isNaN(getAsDate(mode, yearAdjusted)));
    } else {
      setIsValid(false);
    }
  };

  const pickerDate = displayedDate.date ? getAsDate(mode, getDateAdjustedStart(displayedDate.date)) : undefined;
  if(pickerDate && !isNaN(pickerDate)) {
    pickerDate.setDate(pickerDate.getDate());
  }

  useEffect(() => {
    setDisplayedDate({
      date: start?.replace(' ', 'T'),
      version: displayedDate.version,
    });
  }, [start])
  useEffect(() => pickerDate && handleDateTimeChange(new Date(pickerDate)), []);

  const componentClick = () => {
    !isCalendarOpen && setIsCalendarOpen(!isCalendarOpen);
    // this setTimeout is a hack for font not matching in a local environment to non-local environments
    setTimeout(() => {
      document.querySelectorAll('.react-calendar__navigation__prev2-button').forEach(element => {
        element.innerHTML = '<<';
      });
      document.querySelectorAll('.react-calendar__navigation__prev-button').forEach(element => {
        element.innerHTML = '<';
      });
      document.querySelectorAll('.react-calendar__navigation__next2-button').forEach(element => {
        element.innerHTML = '>>';
      });
      document.querySelectorAll('.react-calendar__navigation__next-button').forEach(element => {
        element.innerHTML = '>';
      });
    }, 20);
  };

  let timerId;
  const componentExit = () => {
    timerId = setTimeout(() => {
      setIsCalendarOpen(false);
    }, 500);
  };

  const componentEnter = () => {
    timerId && clearTimeout(timerId);
    timerId = null;
  };

  // TODO: test/execute range components
  return (
    <Wrapper
      flex={flex}
      showLabel={!!label}
      onClick={componentClick}
      onBlur={componentExit}
      onFocus={componentEnter}
      showCalendar={enabled && isCalendarOpen}
      showError={!isValid && pickerDate}
      activeTheme={themeContext}
      isCalendarOpen={isCalendarOpen}
    >
      {label && (
        <StyledLabel activeTheme={themeContext} isNIBRS={isNIBRS} isFutureDate={isFutureDate}>
          {label}
          {tooltip &&
<Tooltip content={tooltip} direction="top" delay={400}>
</Tooltip>
          }
        </StyledLabel>
      )}
      {!range && mode === 'date' && (
        <DatePick
          key={displayedDate.version}
          {...calendar}
          isCalendarOpen={enabled && isCalendarOpen}
          disabled={!enabled}
          value={pickerDate}
          onChange={handleDateTimeChange}
          calendarIcon={
            <Icon color={theme.black} size={16} name="calendar" font="Feather" />
          }
        />
      )}
      {!range && mode === 'datetime' && (
        <DateTimePick
          key={displayedDate.version}
          {...calendar}
          isCalendarOpen={enabled && isCalendarOpen}
          disabled={!enabled}
          value={pickerDate}
          onChange={handleDateTimeChange}
          calendarIcon={
            <Icon color={theme.black} size={16} name="calendar" font="Feather" />
          }
          disableClock={true}
          format={'MM/dd/yyyy HH:mm'}
        />
      )}
      {range && mode === 'date' && (
        <DateRangePick
          isCalendarOpen={false}
          {...calendar}
          disabled={!enabled}
          calendarIcon={
            <Icon color={theme.black} size={16} name="calendar" font="Feather" />
          }
        />
      )}
      {range && mode === 'datetime' && (
        <DateTimeRangePick
          isCalendarOpen={false}
          {...calendar}
          disabled={!enabled}
          disableClock={true}
          calendarIcon={
            <Icon color={theme.black} size={16} name="calendar" font="Feather" />
          }
        />
      )}
    </Wrapper>
  );
};

DateTimePicker.propTypes = {
  binding1: PropTypes.object,
  enabled: PropTypes.bool,
  mode: PropTypes.string,
  label: PropTypes.string,
};

export default DateTimePicker;
export const FieldDatePicker = Field(DateTimePicker);

const StyledLabel = styled(Label)`
color: ${({ isNIBRS, activeTheme, isFutureDate }) => isNIBRS ? isFutureDate ? theme.red : activeTheme.labelNIBRS : isFutureDate ? theme.red : activeTheme.label};
filter: ${({ activeTheme }) => activeTheme.filter};
margin-left: ${theme.labelMarginLeft}px;
`;

const Wrapper = styled.div`
flex: ${({ flex }) => flex};
flex-direction: column;
min-height: ${({ showLabel }) =>
    theme.wrapperHeight - Number(!showLabel) * theme.labelFullHeight}px;
padding: ${theme.componentPadding};
${({ isCalendarOpen }) => isCalendarOpen && 
css `
z-index: 2;
`};


> .react-date-picker,
> .react-datetime-picker,
> .react-daterange-picker,
> .react-datetimerange-picker {
position: relative;
background: ${({ activeTheme }) => activeTheme.inputBackground};
color: ${({ activeTheme }) => activeTheme.inputText};
filter: ${({ activeTheme }) => activeTheme.filter};
border-radius: ${theme.borderRadius}px;
height: ${theme.inputHeight + 6}px;
.react-date-picker__calendar,
.react-datetime-picker__calendar,
.react-daterange-picker__calendar,
.react-datetimerange-picker__calendar {
position: absolute;
margin-top: 5px;
&.react-date-picker__calendar--above-label,
&.react-datetime-picker__calendar--above-label,
&.react-daterange-picker__calendar--above-label,
&.react-datetimerange-picker__calendar--above-label {
bottom: initial;
top: 100%;
}
&.react-date-picker__calendar--closed,
&.react-datetime-picker__calendar--closed,
&.react-daterange-picker__calendar--closed,
&.react-datetimerange-picker__calendar--closed {
display: none !important;
}
> .react-calendar {
${({ showCalendar }) =>
    showCalendar ||
css`
display: none;
`}
font-family: '${theme.baseFont}';
border-color: ${theme.grey};
border-radius: ${theme.borderRadius}px;
flex-direction: column;
overflow: hidden;
width: 300px;
> .react-calendar__navigation {
> .react-calendar__navigation__label {
font-weight: 700;
}
}
> .react-calendar__viewContainer {
> .react-calendar__month-view > div > div {
flex-direction: column;
> .react-calendar__month-view__days {
> .react-calendar__tile {
padding: 0.25em 0.25em 0.25em 0.25em;
font-family: '${theme.baseFont}';
}
> .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
border-radius: 10px;
}
> .react-calendar__tile--active {
background: ${theme.primary};
border-radius: 10px;
}
}
.react-calendar__month-view__weekdays__weekday {
justify-content: center;
}
}
}
> .react-calendar__month-view > div > div {
flex-direction: column;
> .react-calendar__month-view__days {
> .react-calendar__tile {
padding: 0.25em 0.25em 0.25em 0.25em;
font-family: '${theme.baseFont}';
}
> .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
background: inherit;
border-radius: 10px;
abbr {
background: ${theme.grey};
padding: 2px;
border-radius: 10px;
}
}
> .react-calendar__tile--active {
background: inherit;
border-radius: 10px;
abbr {
background: ${theme.primary};
padding: 2px;
border-radius: 10px;
}
}
}
.react-calendar__month-view__weekdays__weekday {
justify-content: center;
}
}
}
}
> .react-date-picker__wrapper,
> .react-datetime-picker__wrapper,
> .react-daterange-picker__wrapper,
> .react-datetimerange-picker__wrapper {
align-items: center;
border-radius: 1px;
border: ${theme.inputBorder};
border-radius: ${theme.borderRadius}px;
transition: ${theme.inputTransition};
width: 100%;
font-family: '${theme.baseFont}';
font-weight: 100;
font-size: .75rem;
${({ showError }) =>
    showError &&
css`
border: 1px solid ${theme.error};
`}
> .react-date-picker__clear-button,
> .react-datetime-picker__clear-button,
> .react-daterange-picker__clear-button,
> .react-datetimerange-picker__clear-button {
display: none;
}
> .react-date-picker__calendar-button,
> .react-datetime-picker__calendar-button,
> .react-daterange-picker__calendar-button,
> .react-datetimerange-picker__calendar-button {
background: ${theme.greywhite};
height: 22px;
border-radius: 0 4px 4px 0;
}
> .react-date-picker__inputGroup,
> .react-datetime-picker__inputGroup,
> .react-daterange-picker__inputGroup,
> .react-datetimerange-picker__inputGroup {
align-items: center;
padding-left: 7px;
> .react-date-picker__inputGroup__input,
> .react-datetime-picker__inputGroup__input,
> .react-daterange-picker__inputGroup__input,
> .react-datetimerange-picker__inputGroup__input,
> .react-datetime-picker__inputGroup__leadingZero {
background: transparent;
font-size: 0.75rem;
outline: 0;
color: ${({ activeTheme }) => activeTheme.inputText};
filter: ${({ activeTheme }) => activeTheme.filter};
}
> .react-datetime-picker__inputGroup__divider {
margin: 0 2px 0 2px;
}
}
}
&.react-date-picker--disabled,
&.react-datetime-picker--disabled,
&.react-daterange-picker--disabled,
&.react-datetimerange-picker--disabled {
cursor: not-allowed;
.react-date-picker__button,
.react-date-picker__inputGroup__input,
.react-daterange-picker__button,
.react-daterange-picker__inputGroup__input {
cursor: not-allowed;
svg {
color: ${theme.grey} !important;
}
}
}
&.react-date-picker--open,
&.react-datetime-picker--open,
&.react-daterange-picker--open,
&.react-datetimerange-picker--open {
.react-date-picker__wrapper,
.react-datetime-picker__wrapper,
.react-daterange-picker__wrapper,
.react-datetimerange-picker__wrapper {
border-color: ${theme.primary};
z-index: 99999;
}
}
}
`;
