import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import FileUpload from '../shared/FileUpload';
import Button from '../shared/Button';
import Modal from '../high-order/Modal';
import { getModel } from 'qcp-js-ui-core/models';
import { handleActions } from 'qcp-js-ui-core/actions';
import styled, { css, ThemeContext } from 'styled-components';
import { failureNotificationMessage } from '../shared/Spinner';
import { addFilterOut, removeFilterOut } from '../../store/actions/notification';
const AWS = require('aws-sdk');

import { LOCAL_URI } from 'qcp-js-ui-core/constants';
import { generateTempQuin } from 'qcp-js-ui-core/utils/uuid';
import {
  addDefaultModel,
  updateProperty,
  insertItem,
} from 'qcp-js-ui-core/store/actions/rms';
import { setNotification } from '../../store/actions/notification';

var uploadsCompleted = 0;

async function addAttachment({ quin, dispatch, getState }, file) {
  const { type, name } = file;
  const fileType = type ? type.split('/')[0]: 'other';
  const tempQuin = generateTempQuin();
  dispatch(addDefaultModel(tempQuin, 'attachment'));
  dispatch(updateProperty(tempQuin, 'fileType', fileType));
  dispatch(updateProperty(tempQuin, 'fileName', name));
  await dispatch(
    handleActions({
      json: 'create',
      quin: tempQuin,
    }),
  );

  const awsAttachmentParameter = getModel(tempQuin, getState().rms)
    ?.awsAttachmentParameters?.[0];
  if (awsAttachmentParameter) {
    const credential = awsAttachmentParameter.Credentials[0];
    const s3Object = awsAttachmentParameter.S3Object[0];
    await uploadFile({
      ...credential,
      ...s3Object,
      file,
      dispatch: dispatch,
      quin,
      tempQuin,
    });
  }
}

const uploadFile = async ({
  SecretAccessKey,
  SessionToken,
  AccessKeyId,
  SSEKMSKeyId,
  Bucket,
  Key,
  Region,
  file,
  dispatch,
  quin,
  tempQuin,
}) => {
  let s3 = new AWS.S3({
    credentials: new AWS.Credentials(
      AccessKeyId,
      SecretAccessKey,
      SessionToken,
    ),
    region: Region,
  });
  const reader = new FileReader();

  reader.onabort = () => console.error('file reading was aborted');
  reader.onerror = () => console.error('file reading has failed');
  reader.onload = () => {
    const arrayBuffer = reader.result;

    const uploadParams = {
      Bucket: Bucket,
      Key: Key,
      Body: arrayBuffer,
      // ContentEncoding: "base64",
      ContentType: file.type,
      ...(typeof SSEKMSKeyId !== 'undefined' && { ServerSideEncryption: 'aws:kms' }),
      ...(typeof SSEKMSKeyId !== 'undefined' && { SSEKMSKeyId: SSEKMSKeyId })
    };

    s3.upload(uploadParams).on('httpUploadProgress', function (progress) {
        let progressSpanId = `prog-${file.path}`
        let progressElement = document.getElementById(progressSpanId);
        let currentFileProgress = parseInt((progress.loaded * 100) / progress.total);
        let progressElementClass = currentFileProgress < 100 ? 'in-progress' : 'complete';
            progressElement.innerHTML = `<span class="${progressElementClass}">Progress: ${currentFileProgress}%</span>`
    }).send(function (error, data) {
      if (error) {
        dispatch(setNotification('File upload failed. Please try again.'));
        console.log(error);
      } else {
        // Only successful uploads should run the inserts and updates
        dispatch(insertItem(quin, 'attachment', tempQuin));
        (async() => await dispatch(
          handleActions({
            json: 'update',
            quin: quin,
          }),
        ))();
        dispatch(updateProperty(tempQuin, LOCAL_URI, name));
        console.log('S3 Upload Success', data.Location);
        uploadsCompleted++;
      }
    });
  };
  reader.readAsArrayBuffer(file);
};

const FileUploadModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const modalContents = useSelector(state => state.modal?.contents);
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const themeContext = useContext(ThemeContext);
  const [uploadStatus, setUploadStatus] = useState('Idle')

  useEffect(() => {
    let totalFileSize = 0;
    if(files.length) {
      files.map((file) => {
        totalFileSize = totalFileSize + file.size;
      });
      setTotalSize(totalFileSize);
      setUploadStatus('Not Started');
    }
  }, [files]);

  const progressTimer = () => {
    setInterval(() => {
      if(uploadsCompleted == files.length) {
          uploadsCompleted = 0;
          setFiles([]);
          clearInterval(progressTimer);
          setUploadStatus(`Completed`);
          setTimeout(() => {
            setUploadStatus(`Idle`);
            closeModal(),
            2000
          });
      } else {
        setUploadStatus(`Uploading...`);
      }
    },500);
  };

  const bringBack = () => {
    dispatch(removeFilterOut(failureNotificationMessage));
  }

  const handleSubmit = () => {
    dispatch(addFilterOut(failureNotificationMessage));
    setTimeout(bringBack, 40000);
    progressTimer();
    if(!files.length) {
      return;
    }
    Promise.all(files.map(async (file) => {
      await addAttachment(modalContents, file);
    }));

  };

  return (
    <>
      <Modal onClose={closeModal} visible={true} title="File Upload">
        <Status status={uploadStatus} activeTheme={themeContext}>
            <h3>Status: <span>{uploadStatus}</span></h3>
        </Status>
        <FileUpload
          files={files}
          setFiles={setFiles}
        />
        <Button type={'primary'} enabled={(uploadStatus == 'Idle' || uploadStatus == 'Not Started') && files?.length > 0 ? true : false} onPress={handleSubmit} visible={true} label="Upload Attachment(s)" />
      </Modal>
    </>
  );
};

const Status = styled.div`
h3 {
    border-right: solid 5px rgba(255,255,255,.75);
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Open Sans';
    text-transform: uppercase;
    margin-left: 25px;
}

h3 span {
    color: rgb(24 85 167);
}
${({ status, activeTheme }) =>
    status == 'Uploading...' &&
css`
h3 {
    animation: animated-text 4s linear 1s infinite alternate both, animated-cursor 600ms linear infinite;
}
h3 span {
    color: ${activeTheme.divider};
}
@keyframes animated-text {
    from{width: 60px;}
    to{width: 170px;}
}
    
@keyframes animated-cursor {
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
}
`}
`;

export default FileUploadModal;