import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from './components/theme';
import { lightTheme, darkTheme } from "./components/Themes"
import  {useDarkMode} from './components/useDarkMode'
import { fetchRmsAppData, userLogout } from 'qcp-js-ui-core/store/actions/authentication';
import {
  markAppAsInitialized,
  deleteAll,
} from 'qcp-js-ui-core/store/actions/rms';
import Navigation, { getQuin } from './implementation/navigation';
import { requestStatus } from 'qcp-js-ui-core/constants';
import { setNotification } from './store/actions/notification';

// TODO: connect notification
import Notification from './components/shared/Notification';
import Content from './components/layout/Content';
import Header from './components/layout/Header';
import Login from './components/modal/Login';
import FourZeroFour from './components/shared/404';

import { useDispatch, useSelector } from 'react-redux';
import { handleActions } from 'qcp-js-ui-core/actions';
import { deepLinkNavigation, navigate, navigateReset, navigateToStartUpScreen } from 'qcp-js-ui-core/store/actions/navigator';
import ViewComponent from './components/ViewComponent';
import Spinner from './components/shared/Spinner';

import configs from 'qcp-js-ui-core/configs';

import store from './store';
import { hideModal } from './store/actions/modal';

let historyMoveHappening = false;

const hardRefresh = (exitTo) => async () => {
  setTimeout(exitTo, 3000);
  await store.dispatch(deleteAll());
};

// TODO: fill with options from the schema or prefer to bind Header to the core
const headerIconOptions = [];

export const logout = async () => {
    if(configs.cognito) {
        await store.dispatch(userLogout());
        if(location.pathname !== '/login') { location.pathname = '/login' }
    } else {
        window.location = window.qcpConfig?.oneappUrl + '/' + window.qcpConfig?.clientName + '/';
    }
}

headerIconOptions.push({
  key: 1,
  message: 'Hard Refresh',
  optionIcon: 'refresh-cw',
  path: '/',
  overrideClick: hardRefresh(() => location.reload()),
});

headerIconOptions.push({
  key: 2,
  message: 'Logout',
  optionIcon: 'lock',
  path: 'logout',
  overrideClick: () => logout(),
});

const loadModel = async (key, quin) => {
  const viewByPath = store.getState().rms.schemas.views.byPath;
  const contextType = viewByPath[key]?.contextType;
  const readActionName = store.getState().rms.schemas.modelSchema[contextType]
    ?.actions?.read;
  await store.dispatch(handleActions({ json: readActionName, quin: quin }));
};

const setupUrl = async (url) => {
  await store.dispatch(fetchRmsAppData());
  if(url === '/') {
    await store.dispatch(navigateToStartUpScreen(Navigation));
  } else {
    const matches = url.split('/');
    matches.shift();
    const loadables = [];
    const navigatables = [];
    while(matches.length > 0) {
      const key = matches.shift();
      const quin = matches.shift();
      const link = {
        href: key,
      };
      loadables.push([key, quin]);
      navigatables.push([link, quin]);
    }
    const seenQuins = {};
    const reducedLoadables = loadables.reduce((result, loadable) => {
      if(!seenQuins[loadable[1]]) {
        result.push(loadable);
      }
      seenQuins[loadable[1]] = true;
      return result;
    }, []);
    await Promise.all(reducedLoadables.map(args => loadModel(...args)));
    for(let i = 0 ; i < navigatables.length ; i++) {
      await store.dispatch(navigate(...navigatables[i], Navigation));
    }
  }
  await store.dispatch(markAppAsInitialized());
  await store.dispatch(hideModal());
  await store.dispatch(deepLinkNavigation(url, Navigation));
};

const dispatchUrl = async (url, dispatch) => (await setupUrl(url, dispatch) && undefined);
const routeAppender = (route, next) => {
  if(!next) { return route; }
  const temporaryLeaders = ['temp', 'uuid'];
  const slashLessLead = next.replace(/^\//, '');
  const split = slashLessLead.split('/');
  if(split.length > 1 && temporaryLeaders.includes(split[1].slice(0, 4))) {
    split.pop();
  }
  return `${route}/${split.join('/')}`;
};


let historylessUrlExpressions;

const shouldPutInHistory = (path) => !historyMoveHappening &&
!historylessUrlExpressions.find((expression) => expression.test(path));

const hasTabPane = (views) => (contents) => {
  if(!contents) { return false; }
  if(Array.isArray(contents)) {
    return contents.find(hasTabPane(views));
    // it will likely need something like this if it starts referencing tabPanes in views inside of views
    // } else if(contents.componentType === 'view' && !contents.contents) {
    // return hasTabPane(views)(views[contents.name])
  } else {
    const childHas = contents?.contents && hasTabPane(views)(contents?.contents);
    return childHas || contents?.componentType === 'tabPane';
  }
};

const getHistorylessUrlRegExp = (path) => {
  const leadingSlash = path[0] === '/' ? '' : '/';
  const deParamaterized = path.replace(/\{[^}]+\}/g, '[^/]+');
  return new RegExp(`^${leadingSlash}${deParamaterized}$`);
};

const findHistorylessUrls = (views) => {
  historylessUrlExpressions = [];
  Object.values(views).forEach(({ path, contents }) => {
    if(!hasTabPane(views)(contents)) { return; }
    historylessUrlExpressions.push(getHistorylessUrlRegExp(path));
  });
};

let lastPath;
let lastRoutes;
const getViewComponent = (args) => {
  const [localTheme, setLocalTheme] = useState('');
  const [urlInitialized, setUrlInitialized] = useState(false);
  const url = args.location.pathname;

  const dispatch = useDispatch();
  const currentRoutes = useSelector(store => store.navigator?.currentRoute || [{}]);

  useEffect(() => {(async () => {
    await dispatchUrl(url, dispatch);
    setUrlInitialized(true);
  })();}, []);

  useEffect(() => {
    window.addEventListener('popstate', historyDispatcher);
    return () => { window.removeEventListener('popstate', historyDispatcher); };
  }, []);

  useEffect(() => {
    setLocalTheme(window.localStorage.getItem('theme'));
}, []);

  const stringifiedRoutes = JSON.stringify(currentRoutes);
  if(stringifiedRoutes === lastRoutes) {
    return renderViewComponent(currentRoutes, urlInitialized);
  }
  lastRoutes = stringifiedRoutes;
  const rawUrl = currentRoutes.map(value => value.link).reduce(routeAppender, '');
  if(urlInitialized && rawUrl.startsWith('///') && window.location.pathname !== '/') {
    if(rawUrl !== lastPath && shouldPutInHistory(rawUrl)) {
      window.history.pushState({url: rawUrl}, '', '/');
    }
  } else if(urlInitialized && !rawUrl.startsWith('///') && window.location.pathname !== rawUrl) {
    if(rawUrl !== lastPath && shouldPutInHistory(rawUrl)) {
      let realUrl = rawUrl;
      let urlSplit = realUrl.split('/');

      if(urlSplit[urlSplit.length-1] == urlSplit[1]) {
        // here we know the URL is getting repeated
        // ie: /reportView/312683824/reportView/itemTableView/312683824
        // need to figure out a clean way of handling this
        urlSplit.splice(urlSplit.length-1, 1);
        realUrl = urlSplit.join('/');
      } else if((urlSplit.length <= 5 && urlSplit[urlSplit.length-1] != urlSplit[1])) {
        window.history.pushState({url: rawUrl}, '', rawUrl);
      }
    }
  }
  lastPath = rawUrl;

  const historyDispatcher = async (event) => {
    historyMoveHappening = true;
    const toGo = event?.state?.url || location.pathname;
    let validURLs = JSON.parse(localStorage.getItem('qsURLs'));
    let validURL = window.location.href;
    validURLs && validURLs.length > 0 ? validURLs.push(validURL) : validURLs = [validURL];
    localStorage.setItem('qsURLs', JSON.stringify(validURLs));

    await dispatch(navigateReset());
    await dispatchUrl(toGo, dispatch);
    historyMoveHappening = false;
  };

  return renderViewComponent(currentRoutes, urlInitialized, localTheme);
};

const renderViewComponent = (currentRoutes, urlInitialized, localTheme) => {
  if(currentRoutes[0]) {
    return urlInitialized ?
      <ViewComponent enabled={true} localTheme={localTheme} key={JSON.stringify(currentRoutes)} route={currentRoutes[0] || {}} baseView={true} /> :
      <div />;
  } else {
    return urlInitialized && window.location.pathname !== '/' ? <FourZeroFour url={window.location.href} /> : <div />;
  }
};

const amLoggedIn = () => {
  return !!localStorage.getItem('AuthenticationToken');
}

let loggedInSetter = () => undefined;
const userChangeChannel = new BroadcastChannel("loginStateChannel");
userChangeChannel.addEventListener('message', (event) => {
  const { username } = JSON.parse(event?.data || {username: null});
  loggedInSetter(!!username);
});

const Routes = () => {
  const schema = useSelector((state) => state.rms.schemas);
  const [isLoggedIn, setLoggedInStatus] = useState(amLoggedIn());
  loggedInSetter = setLoggedInStatus;
  const actionStatus = useSelector(state => state.rms?.actionStatus?.status);
  const [activeTheme, setActiveTheme] = useState('');
  const themeMode = activeTheme === 'light' ? lightTheme : darkTheme;

  if(!historylessUrlExpressions && schema && Object.keys(schema).length) {
    findHistorylessUrls(schema.views);
  }

  if(!schema || Object.keys(schema) === 0) {
    return <div />;
  }

  const handleThemeChange = (active) => {
      setActiveTheme(active)
  }

  if(!isLoggedIn && configs.COGNITO_ENABLED) {
    window.history.replaceState({}, '', '/login');
  }

  return <div style={{width: '100%'}}>
    <Notification />
    {isLoggedIn || !configs.cognito ?
      <div style={{flexDirection: 'column', display: 'block', width: '100%'}}>
          <ThemeProvider theme={themeMode}>
          <GlobalStyle theme={themeMode} />
        <Header options={headerIconOptions} handleThemeChange={handleThemeChange} />
        <StyledContent activeTheme={activeTheme}>
          <Spinner activeTheme={themeMode} className='loading' loading={actionStatus === requestStatus.PROCESSING ? true : false} />
          <Route path="/" component={getViewComponent} />
        </StyledContent>
        </ThemeProvider>
      </div> : <Login />
    }
  </div>;
};

Routes.propTypes = {
};

const getHomepage = () => {
    let appName = window.qcpConfig?.appName != '' ? window.qcpConfig?.appName : window.localAppName;
    const homepageUrls = {
        rms: '/patrolHomePageView',
        ev: '/allEvidenceView'
    }
    let found = Object.entries(homepageUrls).filter(([key, value]) => key == appName );
    return found.length ? found[0][1] : homepageUrls.rms;
}

//<Notification /> // TODO: connect this to redux
const App = ({ store }) => {
    const [activeTheme, themeToggler] = useDarkMode();
    const activeThemeMode = activeTheme === 'light' ? lightTheme : darkTheme;
    const homepage = getHomepage();

return (
  <Provider store={store}>
    <ThemeProvider theme={activeThemeMode}>
      <GlobalStyle theme={activeThemeMode} />
      <Router>
        <Switch>
          <Route exact path='/'>
            <Redirect to={homepage} />
          </Route>
          <Route exact path='/login'>
            <Login />
          </Route>
          <Route path='*' component={Routes} />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>
)
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

const GlobalStyle = createGlobalStyle`
body {
font-family: 'Open Sans';
font-weight: 300;
font-size: .75rem;
background-color: ${({ theme }) => theme.body};
}
body.modal-open {
    .header-wrapper {
        position: relative !important;
    }
}
div {
display: flex;
}
`;

const StyledContent = styled(Content)`
> div {
flex-direction: column;
}

> form {
display: flex;
}
`;

export default App;
