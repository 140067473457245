import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { shallowEqual, useSelector } from 'react-redux';
import { Col } from '../common';

const Title = ({
  value,
  pageTitle,
  size,
}) => {
    const themeContext = useContext(ThemeContext);
  const displayed = (typeof value === 'string' ? value : useSelector(state => getValueFromBindingObject(value, state),
    shallowEqual,
  ));

  return (
    <Wrapper>
      <Col>
        <StyledText as={size} activeTheme={themeContext} pageTitle={pageTitle} size={size}>{displayed}</StyledText>
      </Col>
    </Wrapper>
  );
};

Title.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  horizontalAlign: PropTypes.string,
  size: PropTypes.string,
  pageTitle: PropTypes.bool,
};

export default Title;

const titleSizes = [{
    h1: {
        size: 1.8,
        margin: '24px 0',
        weight: 300
    },
    h2: {
        size: 1.6,
        margin: '24px 0',
        weight: 300
    },
    h3: {
        size: 1.4,
        margin: '24px 0',
        weight: 300
    },
    h4: {
        size: 1.2,
        margin: '24px 0',
        weight: 300
    },
    h5: {
        size: 1.1,
        margin: '24px 0',
        weight: 300
    },
    h6: {
        size: 1.0,
        margin: '24px 0',
        weight: 300
    }
}]

const Wrapper = styled.div`
padding: 0 5px;
`;

const StyledText = styled.div`
color: ${({ activeTheme }) => activeTheme.text};
filter: ${({ activeTheme }) => activeTheme.filter};
font-weight: ${({ size }) => `${titleSizes[0][size]?.weight}`};
font-size: ${({ size }) => `${titleSizes[0][size]?.size}rem`};
margin: ${({ size, pageTitle }) => `${pageTitle ? titleSizes[0][size]?.margin : 0}`};
white-space: nowrap;
`;
